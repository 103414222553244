import React from 'react';

function App() {
  return (
    <div className='App'>
      <h1>Soon.</h1>
    </div>
  );
}

export default App;
